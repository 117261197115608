import { KeyboardEvent, useEffect } from 'react'
import { useForm } from 'react-hook-form'
import { Stack, Box } from '@mui/material'
import { useNotification } from 'hooks'
import { Button, MaskField } from 'components'
import { registerTrack, sanitizeString } from 'helpers'
import { sendWhatsappInvitePatient } from 'services'
import { useInvitePatientContext } from 'contexts'
import { whatsappSchema } from './whatsapp-schema'

interface IWhatsappFormProps {
  direction: 'row' | 'column'
  setAlreadySent?: any
  invitationToken: string
  invitationCode: string
  value?: string
  formId?: string
  source: string
}

function WhatsappForm({
  direction,
  setAlreadySent = () => {},
  invitationToken,
  invitationCode,
  value = '',
  formId,
  source,
}: IWhatsappFormProps) {
  const { control, handleSubmit, setValue } = useForm()
  const { successToast, errorToast } = useNotification()
  const { newQuestionnaireInfos } = useInvitePatientContext()

  const invite = newQuestionnaireInfos?.invite

  useEffect(() => {
    if (value?.startsWith('55') || invite?.whatsapp?.startsWith('55')) {
      setValue('whatsapp', value.slice(2) || invite?.whatsapp.slice(2))
    } else {
      setValue('whatsapp', value)
    }
  }, [setValue, value, invite])

  useEffect(() => {
    if (value) setValue('whatsapp', value)
  }, [setValue, value])

  const onSubmit = async (data: any) => {
    try {
      const validation = await whatsappSchema.isValid(data)

      if (!validation) {
        errorToast('Dados de Whatsapp inválidos')
        return
      }

      const body = {
        invitationCode,
        invitationToken,
        whatsapp: '55' + sanitizeString(data.whatsapp),
      }

      await sendWhatsappInvitePatient(body)

      registerTrack('Questionário enviado', {
        channel: 'whatsapp',
        type: source,
        form_id: formId,
      })

      successToast('Mensagem no WhatsApp enviada com sucesso!')
      setAlreadySent(true)
    } catch (error: any) {
      registerTrack('Erro envio questionário', {
        channel: 'whatsapp',
        type: source,
      })
      errorToast(error?.message || 'Erro desconhecido')
    }
  }

  const checkKeyDown = (e: KeyboardEvent) => {
    if (e.code !== 'Enter') return
    e.preventDefault()
    handleSubmit(onSubmit)()
  }

  return (
    <Box display='flex' flexDirection='column'>
      <Stack
        spacing={1}
        component='form'
        onSubmit={handleSubmit(onSubmit)}
        onKeyDown={checkKeyDown}
        direction={direction}
        alignItems='flex-end'
      >
        <MaskField
          mask='(00) 00000-0000'
          name='whatsapp'
          control={control}
          placeholder='Digite o celular do titular da conta'
        />
        <Button
          color='secondary'
          type='submit'
          sx={{
            height: '42px',
            ...(direction === 'row' && { width: '200px' }),
          }}
        >
          Enviar
        </Button>
      </Stack>
    </Box>
  )
}

export default WhatsappForm
